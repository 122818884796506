@import '~app/mixins';

.row {
  display: flex;
  flex-wrap: nowrap;

  .justifyContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #384048;
      font-size: 15px;
      line-height: 24px;
      font-weight: 600;
    }
  }

  .left {
    width: 70%;

    .hiddenCell {
      opacity: 0;
      visibility: hidden;

      button {
        margin: 0 3px;
      }
    }

    tr:hover .hiddenCell {
      opacity: 1;
      visibility: visible;
    }
  }

  .right {
    width: 30%;
    padding-left: 20px;

    img {
      width: 100%;
    }
  }

  .button {
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
  }

  .tableDocument {
    margin-top: 5px;

    table {
      td:first-child,
      td:nth-child(3) {
        white-space: nowrap;
      }
    }
  }

  .uploadButton {
    .button {
      cursor: pointer;
      padding: 5px 16px;
      border: 1px solid $color-filter-border;
      color: $color-filter-border;
      border-radius: 100px;
      background: $color-white;
      text-align: center;

      &:hover {
        @include primary-color-dark();
        @include primary-border-color-dark();
      }
    }

    & > input {
      display: none;
    }
  }

  .mapPreview {
    position: relative;
    height: 300px;
    width: 100%;
    border-radius: 3px;
    background-color: #d8d8d8;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      padding: 2px;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .btn {
    font-size: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
